.gridContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > ul {
        position: relative;
        display: flex;
        justify-content: center;
        gap: var(--space-12);
        inline-size: fit-content;

        > li:not(:last-child) {
            inline-size: 243px;
        }

        > li.seeAllButton {
            position: absolute;
            top: calc(100% + var(--space-8));
            right: 0;
            z-index: 20;
        }

        @media (max-width: 1300px) {
            > li:nth-of-type(1n + 5):not(.seeAllButton) {
                display: none;
            }
        }

        @media (max-width: 1050px) {
            > li:nth-of-type(1n + 4):not(.seeAllButton) {
                display: none;
            }
        }
    }
}
