.videoSection {
    position: relative;
    max-inline-size: 1136px;
}

.handwrittenNote {
    margin-block-end: 20px;
    gap: var(--space-16);
    span::after {
        display: block;
        content: '';
        block-size: 30px;
    }
}

.videoContainer {
    display: grid;
    place-items: center;
    inline-size: 100%;
    border: 1px solid var(--ui-border);
    border-radius: var(--border-radius-2xl);
    overflow: hidden;

    & > * {
        grid-area: 1/1;
    }

    img {
        object-fit: cover;
        inline-size: 100%;
        block-size: 100%;
    }

    .video {
        inline-size: 100%;
        aspect-ratio: 16 / 9;
    }
}

@media (--screen-lt-md) {
    .handwrittenNote {
        margin-block-end: var(--space-8);
        transform: translateY(var(--space-24));
        gap: 0;
        span::after {
            block-size: 20px;
        }
    }
}
